import React from 'react';
import styled from 'styled-components';
import Image from '../Image';
import { up } from 'styled-breakpoints';

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: 0px;
`;

const Description = styled.p`
  line-height: 24px;
  letter-spacing: 0;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;

  ${Title} {
    margin-top: 40px;
  }
`;

const Content = styled.div`
  display: flex;
  margin-top: 15px;
`;

const Card = styled.article`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 30px;
  box-shadow: 0px 3px 6px #00000026;
  border-radius: 4px;
  width: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};

  ${up('md')} {
    max-width: 320px;
  }
`;

const PostCard = ({ title, description, imagePath }) => {
  return (
    <Card>
      <Header>
        <Image filename={imagePath} alt="Post image" />
        <Title>{title}</Title>
      </Header>
      <Content>
        <Description>{description}</Description>
      </Content>
    </Card>
  );
};

export default PostCard;
