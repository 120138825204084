import React from "react";

import { Header } from "../components/Header";
import Footer from "../components/Footer";
import { Layout } from "../components/Layout";
import Hero from "../sections/Home/Hero";
import Features from "../sections/Features";
import HowItWorks from "../sections/Home/HowItWorks";
import ClientTestimonials from "../sections/ClientTestimonials";
import Newsletter from "../sections/Newsletter";
import LastNews from "../sections/Home/LastNews";
import Stroke from "../components/Stroke";
import { Container } from "../components/Container";
import { SEO } from "../components/SEO";
import FAQ from "../sections/FAQ";
import AboutUs from "../sections/Home/AboutUs";
import WhatWeAre from "../sections/Home/WhatWeAre";
import HowToSubscribe from "../sections/Home/HowToSubscribe";
import ContactSection from "../sections/Home/ContactSection";

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Header />
      <Hero />
      <WhatWeAre />
      <HowItWorks />
      <Container>
        <Stroke />
      </Container>
      <Features />
      <Container>
        <Stroke />
      </Container>
      <HowToSubscribe />
      {/* <ClientTestimonials /> */}
      {/* <LastNews /> */}
      <FAQ />
      <AboutUs />
      <ContactSection />
      <Newsletter />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
