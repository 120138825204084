import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import Section from "../../components/Section";
import { Container } from "../../components/Container";

const WhatWeAreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled.p`
  letter-spacing: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.primary};
  word-wrap: break-word;

  ${up("lg")} {
    font-size: 18px;
  }
`;

const WhatWeAre = () => {
  return (
    <Section.Container id="O que é">
      <Container>
        <Section.Header name="O que é o Colaborador Online?"></Section.Header>
        <WhatWeAreContainer>
          <Description>
            Todos os trabalhadores do Brasil <b> vêm perdendo dinheiro </b> há
            muitos anos.
          </Description>

          <Description>
            No entanto o nosso trabalho vem conseguindo recuperar uma boa parte
            desse dinheiro em favor de trabalhadores como você!
          </Description>

          <Description>
            Você pode não saber, mas o seu empregador é obrigado a reter um
            percentual da sua remuneração mensal e destiná-lo à Previdência
            Social.
          </Description>

          <Description>
            Esse percentual, que varia de 8 a 11%, incide sobre todas as verbas
            que você recebe, como por exemplo: sobre o seu salário, sobre o seu
            décimo terceiro, sobre as suas férias, sobre o seu aviso prévio,
            dentre outras.
          </Description>

          <Description>
            Depois dessa notícia, você deve ter ficado incomodado, se
            perguntando:{" "}
            <b>
              {" "}
              “Ora, mas se é indevido, como faço para buscar esse dinheiro que
              foi descontado indevidamente e passar a não mais sofrer esse tipo
              de injustiça?”
            </b>
          </Description>

          <Description>
            Para conseguir buscar esse dinheiro é necessário que você,
            trabalhador, busque a justiça através de uma ação judicial.
            Considerando que essa ação para você custaria muito tempo e
            dinheiro, o sindicato da sua categoria está apto a representa-lo
            perante o judiciário através de uma ação coletiva.
          </Description>

          <Description>
            É aí que nós da <b> Colaborador OnLine entramos!!</b> Pelo nosso
            portal você pode: tomar conhecimento se o seu sindicato já promoveu
            a ação judicial que te beneficia, aderir a essa ação judicial,
            inclusive mediante a organização dos documentos e dos cálculos
            necessários a esse ressarcimento. Não perca tempo,{" "}
            <b> informe-se! Divulgue! Faça a sua adesão!</b>
          </Description>
        </WhatWeAreContainer>
      </Container>
    </Section.Container>
  );
};

export default WhatWeAre;
