import React from "react";
import Section from "../../components/Section";
import styled from "styled-components";
import { Container } from "../../components/Container";
import FeatureBox from "../../components/FeatureBox/FeatureBox";
import { up } from "styled-breakpoints";

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  ${up("lg")} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const HowItWorksSection = () => {
  const features = [
    {
      title: "Crie sua conta",
      description: "Preencha suas informações.",
      image: {
        url: "how-it-works/fig01.png",
      },
      href: "#",
    },
    {
      title: "Escolha o seu sindicato",
      description: "O sindicato que representa a sua categoria.",
      image: {
        url: "how-it-works/fig02.png",
      },
    },
    {
      title: "Formalize a sua adesão",
      description:
        "Faça a adesão a ação coletiva do sindicado que lhe representa para recuperar o seu crédito.",
      image: {
        url: "how-it-works/fig03.png",
      },
    },
  ];

  return (
    <Section.Container noPaddingTop id="">
      <Container>
        <FeaturesList>
          {features.map((feature, index) => (
            <FeatureBox
              key={index}
              title={`${index + 1}° ${feature.title}`}
              description={feature.description}
              imagePath={feature.image.url}
              href={feature.href ? feature.href : null}
              blueStroke
            />
          ))}
        </FeaturesList>
      </Container>
    </Section.Container>
  );
};

export default HowItWorksSection;
