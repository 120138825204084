import React from "react";
import styled from "styled-components";

import Section from "../components/Section";
import { Container } from "../components/Container";
import Accordion from "../components/Accordion/Accordion";
import { up } from "styled-breakpoints";

const FrequentQuestionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${up("xl")} {
    justify-content: space-between;
    align-items: center;
  }
`;
const FrequentQuestionsSection = () => {
  const items = [
    {
      title: "Tenho valores a recuperar?",
      content:
        "Se você é um colaborador (celetista ou funcionário público) está, obrigatoriamente, contribuindo para um regime de previdência e você possui SIM créditos a serem recuperados.",
    },
    {
      title: "Por quê tenho esse crédito a recuperar?",
      content:
        "Isso ocorre porque, apesar da contribuição à previdência incidir sobre todas as parcelas de remuneração constantes do seu holerite, os tribunais já decidiram que não deveria incidir sobre determinadas parcelas, a exemplo do que ocorre sobre: o terço constitucional de férias; os quinze dias que antecedem o afastamento do trabalhador por motivo de doença ou acidente; e o aviso prévio indenizado.",
    },
    {
      title: "Por qual via consigo recuperar o meu crédito?",
      content:
        "O crédito deve ser requerido pela via judicial. A função desse portal é possibilitar a adesão do colaborador à ação coletiva promovida pela entidade que representa a sua categoria.",
    },
    {
      title: "Quanto tempo demorarei para receber esses créditos?",
      content: `
      O tempo para recebimento dos créditos depende da tramitação da ação coletiva perante o Poder Judiciário, estimando-se em aproximadamente 3 anos a contar da data de distribuição da ação.
      `,
    },
    {
      title: "Devo ter medo do meu empregador ao aderir a essa ação?",
      content:
        "Não! Você, colaborador, não precisa ter qualquer medo porque essa ação não é contra o seu empregador e sim contra o regime de previdência para o qual você contribui (exemplo: o INSS).",
    },
    {
      title: "Qual o valor que tenho a receber? ",
      content:
        "De pronto não é possível estimar exatamente esse valor, porque dependerá da análise da sua documentação em específico. Contudo, fique atento junto à entidade que representa a sua categoria, pois eles estão orientados sobre um valor estimado acerca do crédito que você tem direito.",
    },
    {
      title:
        "Quais os documentos necessários para calcular o meu crédito? Como obtê-los caso eu não tenha guardado?",
      content:
        "Você precisará dos seus últimos 60 holerites. Caso não os tenha guardado, basta solicitá-los ao departamento pessoal do seu empregador. O nosso portal também poderá fazer esse pedido em seu nome. ",
    },
    {
      title:
        "Por qual razão tenho que pagar Taxa de Adesão? Qual a sua finalidade?",
      content:
        "Ao aderir à ação coletiva pelo nosso portal você está, automaticamente, nos autorizando a receber e organizar a sua documentação, além da elaboração das planilhas de cálculo do seu crédito. A taxa de adesão serve para custear essas providências. Essa taxa de adesão pode ser paga imediatamente ou, caso preferir, pode ser descontada do valor do seu crédito ao final.",
    },
    {
      title:
        "Por qual razão tenho que pagar Honorários Advocatícios? Qual a sua finalidade?",
      content:
        "O seu empregador contratou advogados que promoveram a ação coletiva que tem por objetivo recuperar os seus créditos. Os honorários advocatícios servem para remunerar esses profissionais. Esses honorários somente serão pagos caso você receba e serão descontados dos seus créditos ao final.",
    },
  ];
  return (
    <Section.Container id="FAQ" variant="dark">
      <Container>
        <Section.Header
          name="Perguntas frequentes"
          variant="dark"
        ></Section.Header>
        <FrequentQuestionsContainer>
          <Accordion>
            {items.map((item, index) => (
              <div label={item.title} key={index}>
                <p>{item.content}</p>
              </div>
            ))}
          </Accordion>
        </FrequentQuestionsContainer>
      </Container>
    </Section.Container>
  );
};

export default FrequentQuestionsSection;
