import React from 'react';
import Section from '../../components/Section';
import styled from 'styled-components';
import { Container } from '../../components/Container';
import { up } from 'styled-breakpoints';
import PostCard from '../../components/PostCard/PostCard';

const PostContainer = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
`;

const PostsShowcaseContainer = styled.ol`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 30px;

  ${up('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LastNewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LastNews = () => {
  const posts = [
    {
      title: 'Mais de 1 milhão de trabalhadores afastados',
      description:
        'Devido ao distanciamento social, algumas empresas optaram por...',
      image: {
        url: 'posts/thumb1.png',
      },
    },
    {
      title: 'Sindicato cobra Embraer por segurança à saúde',
      description:
        'Representantes da entidade e da fabricante de aeronaves se...',
      image: {
        url: 'posts/thumb2.png',
      },
    },
    {
      title: 'Volta ao trabalho apenas com medidas sanitárias',
      description:
        'O trabalhador pode se recusar a voltar à atividade se a empresa...',
      image: {
        url: 'posts/thumb3.png',
      },
    },
  ];

  return (
    <Section.Container id="Notícias" variant="intermediate">
      <Container>
        <Section.Header
          name="Últimas notícias"
          variant="intermediate"
        ></Section.Header>

        <LastNewsContainer>
          <PostsShowcaseContainer>
            {posts.map((post, index) => (
              <PostContainer>
                <PostCard
                  as="li"
                  key={index}
                  title={post.title}
                  description={post.description}
                  imagePath={post.image.url}
                />
              </PostContainer>
            ))}
          </PostsShowcaseContainer>
        </LastNewsContainer>
      </Container>
    </Section.Container>
  );
};

export default LastNews;
