import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import Section from "../../components/Section";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";

const HowToSubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 430px;
  margin: auto;
`;

const Description = styled.p`
  letter-spacing: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.primary};
  word-wrap: break-word;

  ${up("lg")} {
    font-size: 18px;
  }
`;

const HowToSubscribe = () => {
  return (
    <Section.Container id="">
      <Container>
        <Section.Header name="Como faço para começar?"></Section.Header>
        <HowToSubscribeContainer>
          <Description>
            É tudo muito fácil, rápido e seguro. Não se preocupe, nós vamos te
            orientar durante todo o processo. Comece clicando no botão abaixo:
          </Description>
          <Button primary small>
            Faça a sua adesão
          </Button>
        </HowToSubscribeContainer>
      </Container>
    </Section.Container>
  );
};

export default HowToSubscribe;
