import React, { Component } from "react";
import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";
import styled from "styled-components";

const AccordionSectionsContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin: 0;
  padding: 0;
`;

const AccordionContainer = styled.li`
  margin: 0;
  padding: 0;
`;

class Accordion extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const openSections = {};

    this.state = { openSections };
  }

  onClick = (label) => {
    const {
      state: { openSections },
    } = this;

    const isOpen = !!openSections[label];

    this.setState({
      openSections: {
        [label]: !isOpen,
      },
    });
  };

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this;

    return (
      <AccordionSectionsContainer>
        {children.map((child, index) => (
          <AccordionContainer key={index}>
            <AccordionSection
              isOpen={!!openSections[child.props.label]}
              label={child.props.label}
              onClick={onClick}
            >
              {child.props.children}
            </AccordionSection>
          </AccordionContainer>
        ))}
      </AccordionSectionsContainer>
    );
  }
}

export default Accordion;
