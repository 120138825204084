import React, { useState } from "react";
import Section from "../../components/Section";
import styled from "styled-components";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";
import PuffLoader from "react-spinners/PuffLoader";
import { useSnackbar } from "react-simple-snackbar";
import { useTheme } from "styled-components";
import { successSnackbar, errorSnackbar } from "../../shared/SnackbarSettings";
import { TextAreaInput } from "../../components/Inputs";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { isEmail } from "../../validators";
import { CONTACT_URL } from "../../API/ApiConstants";
import { TextInputLarge } from "../../components/Inputs/TextInputLarge";

const ContactContainer = styled.div``;

const ContactInfo = styled.div`
  font-size: 18px;
  text-align: center;
`;

const ContactForm = styled.form``;

const ContactSection = () => {
  const { colors } = useTheme();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSubmit, errors, control, reset } = useForm();

  const [openSuccessSnackbar] = useSnackbar(successSnackbar(colors));
  const [openErrorSnackbar] = useSnackbar(errorSnackbar(colors));

  const onSubmit = async (formValues) => {
    try {
      setIsSubmitting(true);
      formValues.phone = formValues.phone.slice(3)?.replace(/\D/g, "");

      const response = await fetch(CONTACT_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      const { data: message } = await response.json();

      openSuccessSnackbar(message);

      reset(
        {
          name: "",
          email: "",
          phone: "",
          message: "",
        },
        {
          errors: false,
          dirtyFields: false,
          isDirty: false,
          isSubmitted: false,
          touched: false,
          isValid: false,
          submitCount: false,
        }
      );
    } catch (error) {
      openErrorSnackbar("Falha ao enviar o contato");
    }
    setIsSubmitting(false);
  };

  function phoneMask(val) {
    const prefix = `+55 (${val.substring(2, 4)})`;

    if (val.length <= 12) {
      return prefix + " " + val.substring(4, 8) + "-" + val.substring(8, 12);
    }

    return prefix + " 9 " + val.substring(5, 9) + "-" + val.substring(9, 13);
  }

  return (
    <Section.Container id="Contato" variant="dark">
      <Container>
        <Section.Header name="Contato" variant="dark"></Section.Header>
        <ContactContainer>
          <ContactInfo>Nos envie uma mensagem</ContactInfo>
          <ContactForm onSubmit={handleSubmit(onSubmit)}>
            <Controller
              id="name"
              type="text"
              title="Nome"
              name="name"
              error={errors.name}
              as={TextInputLarge}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 100,
              }}
              required
            />
            <Controller
              id="email"
              type="email"
              title="Email"
              name="email"
              error={errors.email}
              as={TextInputLarge}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 150,
                validate: (value) => isEmail(value),
              }}
              required
            />
            <Controller
              id="phone"
              type="text"
              title="Telefone"
              name="phone"
              error={errors.phone}
              as={
                <NumberFormat
                  customInput={TextInputLarge}
                  format={phoneMask}
                  mask="_"
                />
              }
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 20,
              }}
              required
            />
            <Controller
              id="message"
              title="Mensagem"
              name="message"
              as={TextAreaInput}
              control={control}
              error={errors.message}
              rows={4}
              maxLength={500}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 500,
              }}
              required
            />

            <Button primary>
              {isSubmitting === false ? (
                "Enviar"
              ) : (
                <PuffLoader color={colors.white} size={20} />
              )}
            </Button>
          </ContactForm>
        </ContactContainer>
      </Container>
    </Section.Container>
  );
};

export default ContactSection;
