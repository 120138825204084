import React from "react";
import { up } from "styled-breakpoints";

import styled from "styled-components";
import Section from "../../components/Section";
import { Container } from "../../components/Container";
import { Button } from "../../components/Button";
import Image from "../../components/Image";
import Video from "../../components/Video";
import { Media } from "../../components/Media";
import { Link } from "gatsby";

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  word-wrap: break-word;

  ${up("lg")} {
    font-size: 42px;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: -100px;
  margin: 0 auto;
`;

const ImageBox = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  ${ImageContainer} {
    width: 430px;
  }
`;

const ImageBackgroundStripe = styled.div`
  height: 300px;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 4px;
  opacity: 0.2;
`;

const HeroBackground = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${up("lg")} {
    height: 500px;
  }
`;

const HeroContainer = styled(Container)`
  display: flex;
  align-items: center;
  width: 100%;
  place-content: center;
  height: 100%;

  ${up("lg")} {
    padding: 0 20px;
  }
`;

const Description = styled.p`
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.section.dark.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  word-wrap: break-word;

  ${up("lg")} {
    font-size: 18px;
  }
`;

const CallToActionButtons = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;

  ${up("lg")} {
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
  }
`;

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;

  ${up("lg")} {
    max-width: 530px;
  }

  ${Title} {
    margin-top: 0;
    margin-bottom: 20px;
  }

  ${Description} {
    margin-bottom: 40px;
  }
`;

const HeroSection = () => {
  return (
    <Section.Container id="" variant="dark" disableVerticalMargins>
      <HeroBackground>
        <HeroContainer>
          {/* <Media greaterThanOrEqual="lg">
            <ImageBox>
              <ImageBackgroundStripe />
              <ImageContainer>
                <Image filename="hero/couple.png" alt="Couple" />
              </ImageContainer>
            </ImageBox>
          </Media> */}
          {/* <HeroTextContainer>
            <Title>Seus direitos garantidos pelo sindicato.</Title>
            <Description>
              Colaborador, tire suas dúvidas e saiba como recuperar valores que
              lhe estão sendo indevidamente descontados pela previdência!
            </Description>

            <CallToActionButtons>
              <Link to="/create-account/">
                <Button primary>CLIQUE AQUI</Button>
              </Link>
            </CallToActionButtons>
          </HeroTextContainer> */}

          <Video
            videoSrcURL="https://www.youtube.com/embed/_7FCkbT0AGs"
            videoTitle="Official Music Video on YouTube"
          />
        </HeroContainer>
      </HeroBackground>
    </Section.Container>
  );
};

export default HeroSection;
