import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const IFrameVideo = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 340px;
`;

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoContainer>
    <IFrameVideo
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VideoContainer>
);
export default Video;
