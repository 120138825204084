import styled from 'styled-components';

const Stroke = styled.hr`
  background: ${({ theme }) => theme.colors.gray};
  width: 100%;
  height: 2px;
  margin: 0;
  padding: 0;
`;

export default Stroke;
