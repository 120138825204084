import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import Section from "../../components/Section";
import { Container } from "../../components/Container";

const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled.p`
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.primary};
  word-wrap: break-word;

  ${up("lg")} {
    font-size: 18px;
  }
`;

const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 25px;
  margin-top: 60px;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  padding: 0;
  margin: 0;
  padding-left: 25px;
  border-left: 5px solid ${(props) => props.theme.colors.blue};
  width: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AboutUs = () => {
  const listItems = [
    "Aderir de forma virtual e segura a ações coletivas propostas pelas entidades, possibilitando a restituição de créditos que os trabalhadores tenham direito;",
    "Participar de clube de benefícios oferecidos pelas entidades; e",
    "Ficar por dentro de informações e notícias do seu interesse.",
  ];

  return (
    <Section.Container id="Quem Somos">
      <Container>
        <Section.Header name="Quem Somos"></Section.Header>
        <AboutUsContainer>
          <Description>
            Colaborador Online – Portal Entidade-Trabalhador é uma plataforma
            virtual que tem por objetivo possibilitar a interação entre
            entidades (sindicatos, associações e conselhos) com os seus
            associados.
            <br />
            <br />
            Através do nosso portal, os colaboradores têm a oportunidade de:
          </Description>

          <ListContainer>
            {listItems.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </ListContainer>
        </AboutUsContainer>
      </Container>
    </Section.Container>
  );
};

export default AboutUs;
