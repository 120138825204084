import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Slide, Reveal } from "react-reveal";

const AccordionSectionBox = styled.div``;

const AccordionContentBox = styled.div`
  background: ${({ theme }) => theme.colors.gray};
  margin: 20px;
  margin-left: 40px;
  padding-left: 20px;
  border-left: 5px solid ${({ theme }) => theme.colors.lightGreen};
`;

const CheckBoxContainer = styled.div`
  float: right;
  color: ${(props) =>
    props.isOpen ? props.theme.colors.white : props.theme.colors.midGreen};
`;

const AccordionTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.blue};
  opacity: ${(props) => (props.isOpen ? "0.5" : "1")};
  padding: 30px 25px;
  border-radius: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};

  label {
    max-width: 70%;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 18px;
  }
`;

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <Slide bottom>
        <AccordionSectionBox>
          <AccordionTitleBox isOpen={isOpen} onClick={onClick}>
            <label>{label}</label>
            <CheckBoxContainer isOpen={isOpen}>
              {isOpen && <span>&#9650;</span>}
              {!isOpen && <span>&#9660;</span>}
            </CheckBoxContainer>
          </AccordionTitleBox>

          {isOpen && (
            <Reveal>
              <AccordionContentBox>{this.props.children}</AccordionContentBox>
            </Reveal>
          )}
        </AccordionSectionBox>
      </Slide>
    );
  }
}

export default AccordionSection;
